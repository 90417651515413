import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/landing/pic01.jpg'
import pic02 from '../assets/images/landing/pic02.jpeg'
import pic03 from '../assets/images/landing/pic03.jpeg'
import pic05 from '../assets/images/landing/pic05.jpg'
import pic06 from '../assets/images/landing/pic06.jpg'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Aquarios irrigation and Lighting"
          meta={[
            {
              name: 'description',
              content: 'Aquarios irrigation and Lighting',
            },
            {
              name: 'keywords',
              content:
                'irrigation system, lighting system, new york irrigation system',
            },
          ]}
        ></Helmet>
        <Banner />
        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic01})` }}>
              <header className="major">
                <h3>Irrigation Systems</h3>
                <p>
                  Keeping a garden in good condition. Time, effort, and love are
                  the ingredients for a beautiful garden all year round.
                </p>
              </header>
              <Link to="/irrigation-system" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>Landscape Lighting</h3>
                <p>Be the best house in your neighborhood, even in the night</p>
              </header>
              <Link to="/landscape-lighting" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Natural grass</h3>
                <p>Renew Your Garden Lawn Into An Exciting Space.</p>
              </header>
              <Link to="/natural-grass" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h3>Plumbing Services</h3>
                <p>
                  Most plumbing problems require the assistance of a
                  professional. We offer fast and credible emergency plumbing
                  services
                </p>
              </header>
              <Link to="/our-work" className="link primary"></Link>
            </article>
            <article
              style={{ backgroundImage: `url(${pic06})`, width: '100%' }}
            >
              <header className="major">
                <h3>Building Services</h3>
              </header>
              <Link to="/our-work" className="link primary"></Link>
            </article>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
